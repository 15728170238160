import config from '@configs/index'
import dynamic from 'next/dynamic'
import { GetServerSideProps } from 'next/types'

import { default as AppBase } from '../App'
import { getServerSideStoreInfo } from '../services/ssr'
import { IPage } from '@typesApp/cms'
import { serverSidePropsWrapper } from '@utils/SSR/wrapperGetServerProps'
import { PAGE_TYPES, getHeaders, setHeaders } from '@utils/httpHeadersUtils'
import { dispatchCommonComponents, fetchData, IPageBaseProps } from '@foundation/dataFetch'
import { parseCookies } from '@utils/Cookies'
import { USER_SEGMENT_COOKIE_NAME } from '@constants/common'

const HomePage = dynamic(() => import('@views/Home'), {
  ssr: true,
})

interface IHomeIndexProps extends IPageBaseProps {
  pageData: IPage
}

const HomeIndex: React.FC<IHomeIndexProps> = ({
  pageData,
  siteDataRes,
  locale,
  header,
  megaMenu,
  footer,
  preFooter,
  ...rest
}) => {
  dispatchCommonComponents({ locale, siteDataRes, header, megaMenu, footer, preFooter, rest })

  return (
    <AppBase>
      <HomePage homePageInitial={pageData} />
    </AppBase>
  )
}

export const getServerSideProps: GetServerSideProps<IHomeIndexProps> = serverSidePropsWrapper(
  async (stateManager, context) => {
    const { res, req } = context
    const usergSegments = parseCookies(req)?.[USER_SEGMENT_COOKIE_NAME] ?? ''

    const sectionLimit = parseInt(config.homepageSectionLimit || '0', 10)

    const { pageData, siteDataRes, locale, header, megaMenu, footer, preFooter } = await fetchData({
      context,
      stateManager,
      sectionLimit,
      pageType: PAGE_TYPES.HOME,
    })

    setHeaders(res, getHeaders({ pageType: PAGE_TYPES.HOME, locale, usergSegments }))
    return {
      props: {
        ...(await getServerSideStoreInfo(siteDataRes, locale)),
        pageData: pageData as IPage,
        siteDataRes,
        locale,
        header,
        megaMenu,
        footer,
        preFooter,
      },
    }
  }
)

export default HomeIndex
